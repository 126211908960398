<template>
  <b-card class="submit-payment-body">
    <promo-code
      v-if="hasAmountOverZero"
      class="w-100"
      :checkout-in-progress="processingCheckout"
    />
    <div class="terms-and-conditions-body">
      <b-container fluid>
        <div class="row mb-1">
          <div class="col-12">
            <p class="text-center" style="font-size: 0.75rem;">
              <em>
                By clicking '{{ !verifyOrderCheckout ? checkoutButtonLabel : checkoutButtonLabelVTO }}',
                I accept the
                <a class="link-primary" :href="tosUrl" target="_blank">
                  Terms and Conditions
                </a>
              </em>
            </p>
          </div>
        </div>
      </b-container>
    </div>
    <b-container fluid class="checkout-button-body">
      <div
        class="row mb-1"
        :class="{ panel_active: isBillingActive && !verifyOrderCheckout }"
      >
        <div v-if="!processingCheckout && !verifyOrderCheckout">
          <b-button
            :disabled="checkoutButtonDisabled"
            class="nav-cart-button w-100"
            variant="primary"
            @click="$emit('verify-and-checkout')"
          >
            <span>
              {{ checkoutButtonLabel }}
            </span>
          </b-button>
        </div>
        <div v-else-if="verifyOrderCheckout">
          <b-button
            variant="primary"
            class="button-submit"
            :disabled="submitDisabled"
            @click="$emit('submit-vto')"
          >
            <span>
              {{ checkoutButtonLabelVTO }}
            </span>
          </b-button>
        </div>
      </div>
    </b-container>
    <payment-ssl />
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { dollars } from '@/filters'
import PaymentSsl from '@/components/shared/PaymentSsl.vue'

export default {
  name: 'CheckoutCartSubmitPayment',
  components: {
    PromoCode:                 () => import('@/components/Checkout/PromoCode'),
    PaymentSsl,
  },
  filters: {
    dollars,
  },
  props: {
    verifyOrderCheckout: {
      type: Boolean,
      default: false,
    },
    alternateCheckoutButtonLabel: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isBillingActive: false,
      tosUrl: null,
    }
  },
  computed : {
    ...mapGetters('checkout', [
      'cartEmpty',
      'processingCheckout',
      'checkoutDisabled',
      'cartItemsTotal',
    ]),
    ...mapGetters('paymentMethods', [
      'selectedPaymentMethod',
    ]),
    ...mapGetters('verifyOrder', [
      'upsellProductInCart',
    ]),
    ...mapGetters('website', [
      'website',
    ]),
    // Stageline General Checkout
    checkoutButtonDisabled() {
      return (this.formInvalid || this.checkoutDisabled) && !this.verifyOrderWithEmptyCart
    },
    formInvalid() {
      return (this.cartEmpty || this.missingPaymentMethod)
    },
    verifyOrderWithEmptyCart() {
      return this.verifyOrderCheckout && this.cartEmpty
    },
    checkoutButtonLabel() {
      return this.alternateCheckoutButtonLabel ||
        `Order Now for ${this.$options.filters.dollars(this.cartItemsTotal)}`
    },

    // Verify Order Checkout
    checkoutButtonLabelVTO() {
      return this.upsellProductInCart ? 'Submit & Checkout' : 'Submit'
    },
    submitDisabled() {
      return !this.cartEmpty && this.missingPaymentMethod
    },

    // Promo Code
    hasAmountOverZero() {
      return this.cartItemsTotal > 0.00
    },

    // Shared
    missingPaymentMethod () {
      return (this.selectedPaymentMethod == null || this.selectedPaymentMethod.id == 'ADD')
    },
  },
  async mounted() {
    this.tosUrl = this.$store.getters['website/newTosUrl']
  },
}
</script>

<style lang="scss" scoped>

.accordion-footer {
  text-align: center;

  .button-submit {
    background: $ct-ui-primary !important;
    border-color: $ct-ui-primary !important;

  &:hover {
     cursor: pointer;
     background: $ct-ui-primary-dark !important;
     border-color: $ct-ui-primary !important;
   }
  }
}

.submit-payment-body {
  border-top: none !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;

  .terms-and-conditions-body {
    margin-top: 1em;
  }
  .checkout-button-body {
    display: flex;
    justify-content: center;
    align-items: center;

    .nav-cart-button {
      border-radius: 4px !important;
      padding: 8px 16px 8px 10px !important;
    }
  }
  .button-submit {
    background: $ct-ui-primary !important;
    border-color: $ct-ui-primary !important;

    &:hover {
      cursor: pointer;
      background: $ct-ui-primary-dark !important;
      border-color: $ct-ui-primary !important;
    }
  }
}
.payment-ssl-badge {
  width: 5.5em;
  margin: 0.5em auto 0;
}

</style>
