var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { staticClass: "submit-payment-body" },
    [
      _vm.hasAmountOverZero
        ? _c("promo-code", {
            staticClass: "w-100",
            attrs: { "checkout-in-progress": _vm.processingCheckout },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "terms-and-conditions-body" },
        [
          _c("b-container", { attrs: { fluid: "" } }, [
            _c("div", { staticClass: "row mb-1" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "p",
                  {
                    staticClass: "text-center",
                    staticStyle: { "font-size": "0.75rem" },
                  },
                  [
                    _c("em", [
                      _vm._v(
                        "\n              By clicking '" +
                          _vm._s(
                            !_vm.verifyOrderCheckout
                              ? _vm.checkoutButtonLabel
                              : _vm.checkoutButtonLabelVTO
                          ) +
                          "',\n              I accept the\n              "
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "link-primary",
                          attrs: { href: _vm.tosUrl, target: "_blank" },
                        },
                        [
                          _vm._v(
                            "\n                Terms and Conditions\n              "
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-container",
        { staticClass: "checkout-button-body", attrs: { fluid: "" } },
        [
          _c(
            "div",
            {
              staticClass: "row mb-1",
              class: {
                panel_active: _vm.isBillingActive && !_vm.verifyOrderCheckout,
              },
            },
            [
              !_vm.processingCheckout && !_vm.verifyOrderCheckout
                ? _c(
                    "div",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "nav-cart-button w-100",
                          attrs: {
                            disabled: _vm.checkoutButtonDisabled,
                            variant: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("verify-and-checkout")
                            },
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.checkoutButtonLabel) +
                                "\n          "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm.verifyOrderCheckout
                ? _c(
                    "div",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "button-submit",
                          attrs: {
                            variant: "primary",
                            disabled: _vm.submitDisabled,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("submit-vto")
                            },
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.checkoutButtonLabelVTO) +
                                "\n          "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
      _c("payment-ssl"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }